// storageUtils.js

import Cookies from 'js-cookie';

export const getData = async (key) => {
  let value = null;

  try {
    value = localStorage.getItem(key);
    if (value !== null) {
      console.debug("getItem:", key);
      try {
        value = JSON.parse(value);
      } catch (error) {
        console.log("Failed to parse JSON from localStorage: ", key);
      }
    }

    return value;
  } catch (error) {
    console.error("Error al obtener datos de localStorage para clave: ", key);
  }

  try {
    value = sessionStorage.getItem(key);
    if (value === null) {
      console.debug("getItem:", key);
      try {
        value = JSON.parse(value);
      } catch (error) {
        console.log("Failed to parse JSON from sessionStorage: ", key);
      }
    }

    return value;
  } catch (error) {
    console.error("Error al obtener datos de sessionStorage para clave: ", key);
  }

  try {
    value = Cookies.get(key);
    if (value === null) {
      console.debug("getItem:", key);
      try {
        value = JSON.parse(value);
      } catch (error) {
        console.log("Failed to parse JSON from cookies: ", key);
      }
    }

    return value;
  } catch (error) {
    console.error("Error al obtener datos de las cookies para clave: ", key);
  }

  return null;
}

export const saveData = async (key, value) => {
  try {
    console.debug("setItem (localStorage):", key);
    localStorage.setItem(key, JSON.stringify(value));
    console.debug("setted OK.");
  } catch (error) {
    console.error("Error al guardar en localStorage:", error);
  }

  try {
    console.debug("setItem (sessionStorage):", key);
    sessionStorage.setItem(key, JSON.stringify(value));
    console.debug("setted OK.");
  } catch (error) {
    console.error("Error al guardar en sessionStorage:", error);
  }

  try {
    Cookies.set(key, JSON.stringify(value), {expires: 365, sameSite: "None", secure: true});
  } catch (error) {
    console.error("Error al guardar en las cookies:", error);
  }
}

export const removeData = async (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error("Error al eliminar en localStorage:", error);
  }

  try {
    sessionStorage.removeItem(key);
  } catch (error) {
    console.error("Error al eliminar en sessionStorage:", error);
  }

  try {
    Cookies.remove(key, {sameSite: "None", secure: true});
  } catch (error) {
    console.error("Error al eliminar en las cookies:", error);
  }
}
