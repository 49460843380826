import React, {useState, createContext, useContext, useEffect} from 'react';
import {getData, saveData} from "../component/misc/StorageUtils";
import axios from "axios";
import {useUserContext} from "./UserContext";
import {doc, getDoc, onSnapshot} from "firebase/firestore";
import {firestore} from "../firebase";

const TokenContext = createContext(null);

export const useTokenContext = () => {
  return useContext(TokenContext);
};

export const TokenProvider = ({children}) => {
  const {user} = useUserContext();
  const [submitting, setSubmitting] = useState(false);
  const [tokenData, setTokenData] = useState(null);
  const [privateTokensDocRef, setPrivateTokensDocRef] = useState(null);

  useEffect(() => {
    if (user) {
      setPrivateTokensDocRef(doc(firestore, `private_tokens/${user.uid}`));
    } else {
      setTokenData(null);
      setPrivateTokensDocRef(null);
    }
  }, [user]);

  useEffect(() => {
    let unsubscribe;

    if (privateTokensDocRef) {
      let tokenData = null;
      unsubscribe = onSnapshot(
        privateTokensDocRef,
        {source: "server"},
        async (docSnapshot) => {
          if (docSnapshot.exists()) {
            console.log("El documento tokens ya existe");
            tokenData = docSnapshot.data();
          } else {
            console.log("El documento tokens no existe");
            tokenData = {
              "purchasedCredits": 0
            }
          }

          setTokenData(tokenData);
          await saveData(`tokenData`, tokenData);
        },
        (error) => {
          console.log("Error al obtener el documento:", error);
        }
      );
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  },[privateTokensDocRef]);

  const tokenFunctions = {
    fetchTokenData: async () => {
      try {
        const storedTokenData = await getData(`tokenData`);
        if (storedTokenData == null) {
          console.log(`Trying to fetch: private_tokens/${user.uid}`);
          const privateTokensRef = doc(
            firestore,
            `private_tokens/${user.uid}`
          );
          const docSnapshot = await getDoc(privateTokensRef);

          if (docSnapshot.exists()) {

            const data = docSnapshot.data();

            await saveData(`tokenData`, data);
            return data;
          } else {
            throw new Error('No se encontró información de tokens');
          }
        } else {
          return storedTokenData;
        }
      } catch (error) {
        console.error('Error al obtener los datos de tokens:', error);
        return null;
      }
    },
    getTokenData: async () => {
      const tokenData = await getData(`tokenData`);
      if (tokenData) {
        return tokenData;
      } else {
        return await tokenFunctions.fetchTokenData();
      }
    },
    generateToken: async () => {
      setSubmitting(true);

      let token = null;

      // Llama a la Cloud Function para generar y recuperar el token
      const functionUrl = "https://europe-west1-traineer-5e135.cloudfunctions.net/generateToken";

      try {
        const response = await axios.post(functionUrl, {userUid: user.uid});

        if (response.status < 400) {
          console.log("Token generado correctamente.");
          console.log("Data de la respuesta:", response.data);  // Esta línea va a imprimir el cuerpo de la respuesta.
          token = response.data.data;  // Aquí se accede a la propiedad 'token' del cuerpo de la respuesta.
        } else {
          console.log("Ha habido un problema al generar el token.");
        }
      } catch (error) {
        console.error("Error al generar el token:", error);
      }

      setSubmitting(false);

      return token;
    },
    redeemToken: async (token) => {
      setSubmitting(true);

      // Llama a la Cloud Function para generar y recuperar el token
      const functionUrl = "https://europe-west1-traineer-5e135.cloudfunctions.net/redeemToken";

      try {
        const response = await axios.post(functionUrl, {userUid: user.uid, key: token});

        if (response.status < 400) {
          console.log("Token canjeado correctamente.");
          setSubmitting(false);
          return true;
        } else {
          console.log("Ha habido un problema al canjear el token.");
        }
      } catch (error) {
        console.error("Error al canjear el token:", error);
      }

      setSubmitting(false);
      return false;
    }
  };

  return (
    <TokenContext.Provider value={{ tokenData, submitting, ...tokenFunctions }}>
      {children}
    </TokenContext.Provider>
  );
};
