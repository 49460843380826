import {createContext, useContext, useState, useEffect} from "react";
import {doc, onSnapshot, getDoc, getDocs, collection} from "firebase/firestore";
import {auth, firestore} from "../firebase.js";
import {onAuthStateChanged} from "firebase/auth";
import axios from "axios";
import {getData, removeData, saveData} from "../component/misc/StorageUtils";

const UserContext = createContext(null);

export const useUserContext = () => {
  return useContext(UserContext);
};

export const UserProvider = ({children}) => {
  const [user, setUser] = useState(
    async () => {
      const storedAuthState = await getData("authState");
      return storedAuthState ? storedAuthState.user : null;
    }
  );
  const [userData, setUserData] = useState(null);
  const [userDocRef, setUserDocRef] = useState(null);
  const [listenerSet, setListenerSet] = useState(false);

  // Escuchar los cambios en el objeto 'user'
  useEffect(() => {
    if (user) {
      setUserDocRef(doc(firestore, `webUsers/${user.uid}`));
    } else {
      setUserData(null);
      setUserDocRef(null);
    }
  }, [user]);

  const trainingFunctions = {
    fetchTrainingSession: async (timestamp) => {
      try {
        const storedTrainingSession = await getData(`training-${timestamp}`);
        if (storedTrainingSession == null) {
          console.log(`Trying to fetch: trainings/${user.uid}/generatedTrainings/${timestamp}`);
          const trainingSessionRef = doc(
            firestore,
            `trainings/${user.uid}/generatedTrainings/${timestamp}`
          );
          const docSnapshot = await getDoc(trainingSessionRef);

          if (docSnapshot.exists()) {

            const data = docSnapshot.data();

            await saveData(`training-${timestamp}`, data);
            return data;
          } else {
            throw new Error('No se encontró la sesión de entrenamiento');
          }
        } else {
          return storedTrainingSession;
        }
      } catch (error) {
        console.error('Error al obtener la sesión de entrenamiento:', error);
        return null;
      }
    },
    getTrainingSession: async (timestamp) => {
      const storedSession = await getData(`training-${timestamp}`);
      if (storedSession) {
        return storedSession;
      } else {
        return await trainingFunctions.fetchTrainingSession(timestamp);
      }
    },
    clearTrainingSessions: () => {
      for (let key in localStorage) {
        if (key.startsWith('training-')) {
          removeData(key);
        }
      }
    },
  };

  const recordedTrainingFunctions = {
    fetchRecordedTraining: async (timestamp) => {
      try {
        const storedTrainingSession = await getData(`recordedTraining-${timestamp}`);
        if (storedTrainingSession == null) {
          console.log(`Trying to fetch: trainings/${user.uid}/recordedTrainings/${timestamp}`);

          // Crear una referencia a la subcolección 'recordedSessions'
          const recordedSessionsRef = collection(
            firestore,
            `trainings/${user.uid}/recordedTrainings/${timestamp}/recordedSessions`
          );

          // Obtener todos los documentos de la subcolección 'recordedSessions'
          const recordedSessionsSnapshot = await getDocs(recordedSessionsRef);

          // Si la subcolección 'recordedSessions' no tiene documentos, lanzar un error
          if (recordedSessionsSnapshot.empty) {
            throw new Error('No se encontró la sesión de entrenamiento grabada');
          }

          // Mapear los documentos de la subcolección a un array de objetos
          let recordedSessions = recordedSessionsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
          }));

          // Crear los datos que se van a devolver
          let data = {
            recordedSessions: recordedSessions,
          };

          await saveData(`recordedTraining-${timestamp}`, data);
          return data;
        } else {
          return storedTrainingSession;
        }
      } catch (error) {
        console.info('', error);
        return null;
      }
    },
    getRecordedTraining: async (timestamp) => {
      const storedSession = await getData(`recordedTraining-${timestamp}`);
      if (storedSession) {
        return storedSession;
      } else {
        return await recordedTrainingFunctions.fetchRecordedTraining(timestamp);
      }
    },
    setRecordedTrainingSession: async (timestamp, index) => {
      try {
        const functionUrl = "https://europe-west1-traineer-5e135.cloudfunctions.net/updateRecordedTraining";
        const storedTraining = await getData(`recordedTraining-${timestamp}`);

        // Extrae la sesión grabada
        storedTraining.recordedSessions[index].finished = true;
        const session = storedTraining.recordedSessions[index];
        await saveData(`recordedTraining-${timestamp}`, storedTraining);

        if (session) {
          axios
            .post(functionUrl, {userUid: user.uid, session: session, timestamp: timestamp, index: index})
            .then(async (response) => {
              if (response.status === 200)
                console.log("Entrenamiento modificado correctamente.");
              else if (response.status === 400) {
                console.log("La sesión de entrenamiento ya ha finalizado. Recuperando la sesión de entrenamiento...");

                // Recuperar la sesión de entrenamiento y guardar los datos
                const newSession = await recordedTrainingFunctions.getRecordedTraining(timestamp);
                await saveData(`recordedTraining-${timestamp}`, newSession);
              } else {
                console.log("Ha habido un problema modificando el entrenamiento.");
              }
            })
            .catch((error) => {
              console.error("Error al dar de alta el entrenamiento:", error);
            });
        } else {
          throw new Error(`No se encontró la sesión de entrenamiento grabada con índice ${index} para enviar`);
        }
      } catch (error) {
        console.error("Error al intentar enviar la sesión de entrenamiento grabada:", error);
      }
    },
    clearRecordedTrainings: () => {
      for (let key in localStorage) {
        if (key.startsWith('recordedTraining-')) {
          removeData(key);
        }
      }
    },
  };

  const setTermsAndConditionsAccepted = () => {
    try {
      if (userData && userData.operationalData && !userData.operationalData.termsAndConditionsAccepted) {
        const functionUrl = "https://europe-west1-traineer-5e135.cloudfunctions.net/updateTermsAndConditions";

        axios
          .post(functionUrl, {userUid: user.uid})
          .then(async (response) => {
            if (response.status === 200)
              console.log("Entrenamiento modificado correctamente.");
            else if (response.status === 400) {
              console.log("Error al 400 intentar actualizar los términos y condiciones...");
            } else {
              console.log("Ha habido un problema modificando los términos y condiciones.");
            }
          })
          .catch((error) => {
            console.error("Error al intentar actualizar los términos y condiciones:", error);
          });
      }
    } catch (error) {
      console.error("Error al intentar actualizar los términos y condiciones:", error);
    }
  }

  const updateBusinessFormulary = (fiscalData) => {
    try {
      if (userData && !userData.fiscalData) {
        const functionUrl = "https://europe-west1-traineer-5e135.cloudfunctions.net/updateBusinessFormulary";

        axios
          .post(functionUrl, {userUid: user.uid, fiscalData: fiscalData})
          .then(async (response) => {
            if (response.status < 400)
              console.log("FiscalData modificado correctamente.");
            else if (response.status === 400) {
              console.log("Error al 400 intentar actualizar los datos fiscales.");
            } else {
              console.log("Ha habido un problema modificando los datos fiscales.");
            }
          })
          .catch((error) => {
            console.error("Error al intentar actualizar los datos fiscales:", error);
          });
      }
    } catch (error) {
      console.error("Error al intentar actualizar los datos fiscales:", error);
    }
  }

  // Gestionar las solicitudes a Firestore
  useEffect(() => {
    let unsubscribe;

    if (!listenerSet && userDocRef && (!userData || userData.uid === null || user.uid !== userData.uid)) {
      setListenerSet(true);
      let userLocalData = null;

      unsubscribe = onSnapshot(
        userDocRef,
        {source: "server"},
        async (docSnapshot) => {
          if (docSnapshot.exists()) {
            console.log("El documento ya existe");
            userLocalData = docSnapshot.data();
          } else {
            console.log("El documento no existe");

            // Llama a la Cloud Function para dar de alta los datos del usuario
            const functionUrl = "https://europe-west1-traineer-5e135.cloudfunctions.net/updateUserData";

            axios
              .post(functionUrl, {userUid: user.uid})
              .then((response) => {
                if (response.status === 200) {
                  console.log("Usuario actualizado correctamente.");
                } else {
                  console.log("Ha habido un problema actualizando el usuario.");
                }
                userLocalData = docSnapshot.data();
              })
              .catch((error) => {
                console.error("Error al inicializar el usuario:", error);
              });
          }

          // Actualizar el estado de userData con los datos recuperados o creados en Firestore
          setUserData(userLocalData);

          // Guardar los datos del usuario en localStorage
          await saveData(`user-${user.uid}`, userLocalData);
        },
        (error) => {
          console.log("Error al obtener el documento:", error);
        }
      );
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }

      // Resetear el estado de listenerSet cuando se desmonte el componente o cuando cambien userDocRef o user
      setListenerSet(false);
    };
  }, [userDocRef, user]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // El usuario ha iniciado sesión
        setUser(user);
        saveData("authState", {status: "loggedIn", user});
      } else {
        // El usuario ha cerrado sesión
        setUser(null);
        setUserData(null);
        removeData("authState");
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleSignUp = async (user) => {
    try {
      setUser(user);
      // Revisa si existe un friendCode en localStorage y cookies
      const friendCode = await getData("friendCode");

      // Obtén el userData actualizado
      const userLocalData = await getData(`user-${user.uid}`);
      setUserData(userLocalData);

      // Si existe un friendCode, actualiza el campo referred y agrega créditos a ambas cuentas
      if (friendCode && (!userLocalData.referredBy || userLocalData.referredBy.trim() === '')) {
        await updateCreditsOnSignUp(user, friendCode);
      }
    } catch (error) {
      console.log("Error al crear el usuario:", error);
    }
  };

  const updateCreditsOnSignUp = async (user, friendCode) => {
    // Llama a la Cloud Function para incrementar los créditos en ambas cuentas
    const functionUrl = 'https://europe-west1-traineer-5e135.cloudfunctions.net/updateCreditsOnSignUp';

    axios
      .post(functionUrl, {userUid: user.uid, referredUserUid: friendCode})
      .then((response) => {
        console.log("Créditos actualizados correctamente");
      })
      .catch((error) => {
        console.error("Error al actualizar los créditos:", error);
      });
  };

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      console.log('Sesión cerrada correctamente.', auth);
      await removeData(`user-${user.uid}`);
      setUser(null);
      setUserData(null);
      trainingFunctions.clearTrainingSessions();
      recordedTrainingFunctions.clearRecordedTrainings();
      await removeData("authState");
      await removeData("subscriptionCredits");
      await removeData("purchasedCredits");
      await removeData("tokenData");
    } catch (error) {
      console.log('Error al cerrar la sesión:', error);
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        userData,
        handleSignOut,
        handleSignUp,
        updateCreditsOnSignUp,
        ...trainingFunctions,
        ...recordedTrainingFunctions,
        setTermsAndConditionsAccepted,
        updateBusinessFormulary
      }}>
      {children}
    </UserContext.Provider>
  );
};
