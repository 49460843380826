import React, { useState, createContext, useContext, useEffect } from 'react';
import { doc, getDoc, onSnapshot, collection, getDocs } from "firebase/firestore";
import { firestore } from "../firebase";
import { getData, saveData } from "../component/misc/StorageUtils";
import { useUserContext } from "./UserContext";

const BusinessContext = createContext(null);

export const useBusinessContext = () => {
  return useContext(BusinessContext);
};

export const BusinessProvider = ({ children }) => {
  const { user } = useUserContext();
  const [businessMetaData, setBusinessMetaData] = useState(null);
  const [companyMetaData, setCompanyMetaData] = useState(null);
  const [readOnlyTrainingPoints, setReadOnlyTrainingPoints] = useState(null);
  const [historicalData, setHistoricalData] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const businessMetaDataDocRef = user ? doc(firestore, `global_metaData/business_metaData`) : null;
  const companyMetaDataDocRef = user ? doc(firestore, `global_metaData/company_metaData`) : null;
  const trainingPointsMetaDataDocRef = user ? doc(firestore, `global_metaData/readOnly_trainingPoints`) : null;
  const historicalMetaDataCollectionRef = user ? collection(firestore, `global_metaData/historical_metaData/data`) : null;

  useEffect(() => {
    const fetchData = async () => {
      if (businessMetaDataDocRef) {
        const businessMetaDoc = await getDoc(businessMetaDataDocRef);
        if (businessMetaDoc.exists()) {
          setBusinessMetaData(businessMetaDoc.data());
        }
      }

      if (companyMetaDataDocRef) {
        const companyMetaDoc = await getDoc(companyMetaDataDocRef);
        if (companyMetaDoc.exists()) {
          setCompanyMetaData(companyMetaDoc.data());
        }
      }

      if (trainingPointsMetaDataDocRef) {
        const trainingPointsDoc = await getDoc(trainingPointsMetaDataDocRef);
        if (trainingPointsDoc.exists()) {
          setReadOnlyTrainingPoints(trainingPointsDoc.data());
        }
      }

      if (historicalMetaDataCollectionRef) {
        const historicalDocs = await getDocs(historicalMetaDataCollectionRef);
        const historicalDataArray = historicalDocs.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setHistoricalData(historicalDataArray.sort((a, b) => new Date(a.id) - new Date(b.id)));
      }

      // Save data locally
      await saveData('businessMetaData', businessMetaData);
      await saveData('companyMetaData', companyMetaData);
      await saveData('readOnlyTrainingPoints', readOnlyTrainingPoints);
      await saveData('historicalData', historicalData);
    };

    if (user) {
      fetchData();
    }
  }, [user]);

  const fetchLocalData = async () => {
    const storedBusinessMetaData = await getData('businessMetaData');
    const storedCompanyMetaData = await getData('companyMetaData');
    const storedReadOnlyTrainingPoints = await getData('readOnlyTrainingPoints');
    const storedHistoricalData = await getData('historicalData');

    if (storedBusinessMetaData) setBusinessMetaData(storedBusinessMetaData);
    if (storedCompanyMetaData) setCompanyMetaData(storedCompanyMetaData);
    if (storedReadOnlyTrainingPoints) setReadOnlyTrainingPoints(storedReadOnlyTrainingPoints);
    if (storedHistoricalData) setHistoricalData(storedHistoricalData.sort((a, b) => new Date(a.id) - new Date(b.id)));
  };

  const businessFunctions = {
    fetchBusinessMetaData: async () => {
      try {
        const businessData = await getData('businessMetaData');
        if (businessData) {
          setBusinessMetaData(businessData);
          return businessData;
        } else {
          throw new Error('No se encontró información de business_metaData');
        }
      } catch (error) {
        console.error('Error al obtener los datos de business_metaData:', error);
        return null;
      }
    },
    getBusinessMetaData: async () => {
      if (businessMetaData) {
        return businessMetaData;
      } else {
        return await businessFunctions.fetchBusinessMetaData();
      }
    },
    // Puedes añadir otras funciones similares para companyMetaData, readOnlyTrainingPoints, etc.
  };

  useEffect(() => {
    if (!businessMetaData && !companyMetaData && !readOnlyTrainingPoints && !historicalData.length) {
      fetchLocalData();
    }
  }, []);

  return (
      <BusinessContext.Provider value={{
        businessMetaData,
        companyMetaData,
        readOnlyTrainingPoints,
        historicalData,
        submitting,
        ...businessFunctions,
      }}>
        {children}
      </BusinessContext.Provider>
  );
};
