import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import {getStorage, ref} from "firebase/storage";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: "https://traineer-5e135-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "traineer-5e135",
  storageBucket: "traineer-5e135.appspot.com",
  messagingSenderId: "721052197609",
  appId: "1:721052197609:web:db309fad132fa5ef84c678",
  measurementId: "G-5L24NBXRZD",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const functions = getFunctions(app, "europe-west1");
export const firestore = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);

const storageRef = ref(storage);
export const trainingsRef = ref(storageRef, 'trainings');
export const googleAuthProvider = new GoogleAuthProvider();

export { analytics, functions };

// Función para registrar eventos de navegación en Analytics
export function logPageView(pageName, campaign) {
  logEvent(analytics, "page_view", { page_name: pageName, campaign: campaign });
}
// Función para registrar eventos de botones en Analytics
export function logButtonTouched(buttonName, campaign, information) {
  logEvent(analytics, "button_touched", { button_name: buttonName, campaign: campaign, more_information: information });
}
// Función para registrar eventos de funnel en Analytics
export function logFunnelStage(stageName, campaign) {
  logEvent(analytics, "funnel_stage", { stage_name: stageName, campaign: campaign });
}
// Función para registrar eventos de user path en Analytics
export function logUserPath(pathName, campaign) {
  logEvent(analytics, "user_path", { path_name: pathName, campaign: campaign });
}

document.addEventListener("DOMContentLoaded", function () {

});

