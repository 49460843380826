// i18n.js
import i18n from 'i18next';
import detector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
import {initReactI18next} from 'react-i18next';

/*
import translationDE from './locales/de/translation.json';
import cookiesDE from './locales/de/cookies.json';
import exercisesDE from './locales/de/exercises.json';
import formularyDE from './locales/de/formulary.json';
import privacyDE from './locales/de/privacy.json';
import refundDE from './locales/de/refund-policy.json';
import termsOfServiceDE from './locales/de/terms-of-service.json';
import termsOfSubscriptionDE from './locales/de/terms-of-subscription.json';
 */
import translationEN from './locales/en/translation.json';
import cookiesEN from './locales/en/cookies.json';
import exercisesEN from './locales/en/exercises.json';
import formularyEN from './locales/en/formulary.json';
import privacyEN from './locales/en/privacy.json';
import refundEN from './locales/en/refund-policy.json';
import termsOfServiceEN from './locales/en/terms-of-service.json';
import termsOfSubscriptionEN from './locales/en/terms-of-subscription.json';
import translationES from './locales/es/translation.json';
import cookiesES from './locales/es/cookies.json';
import exercisesES from './locales/es/exercises.json';
import formularyES from './locales/es/formulary.json';
import privacyES from './locales/es/privacy.json';
import refundES from './locales/es/refund-policy.json';
import termsOfServiceES from './locales/es/terms-of-service.json';
import termsOfSubscriptionES from './locales/es/terms-of-subscription.json';
import tutorialES from './locales/es/tutorial.json';
import hintsES from './locales/es/hints.json';
import motivationES from './locales/es/motivation.json';
import faqES from './locales/es/faq.json';
/*
import translationFR from './locales/fr/translation.json';
import cookiesFR from './locales/fr/cookies.json';
import exercisesFR from './locales/fr/exercises.json';
import formularyFR from './locales/fr/formulary.json';
import privacyFR from './locales/fr/privacy.json';
import refundFR from './locales/fr/refund-policy.json';
import termsOfServiceFR from './locales/fr/terms-of-service.json';
import termsOfSubscriptionFR from './locales/fr/terms-of-subscription.json';
import translationIT from './locales/it/translation.json';
import cookiesIT from './locales/it/cookies.json';
import exercisesIT from './locales/it/exercises.json';
import formularyIT from './locales/it/formulary.json';
import privacyIT from './locales/it/privacy.json';
import refundIT from './locales/it/refund-policy.json';
import termsOfServiceIT from './locales/it/terms-of-service.json';
import termsOfSubscriptionIT from './locales/it/terms-of-subscription.json';
 */

const resources = {
  /*de: {
    translation: translationDE,
    cookies: cookiesDE,
    exercises: exercisesDE,
    formulary: formularyDE,
    privacy: privacyDE,
    refund: refundDE,
    termsOfService: termsOfServiceDE,
    termsOfSubscription: termsOfSubscriptionDE
  },*/
  en: {
    translation: translationEN,
    cookies: cookiesEN,
    exercises: exercisesEN,
    formulary: formularyEN,
    privacy: privacyEN,
    refund: refundEN,
    termsOfService: termsOfServiceEN,
    termsOfSubscription: termsOfSubscriptionEN
  },
  es: {
    translation: translationES,
    cookies: cookiesES,
    exercises: exercisesES,
    formulary: formularyES,
    privacy: privacyES,
    refund: refundES,
    termsOfService: termsOfServiceES,
    termsOfSubscription: termsOfSubscriptionES,
    tutorial: tutorialES,
    hints: hintsES,
    motivation: motivationES,
    faq: faqES
  }/*,
  fr: {
    translation: translationFR,
    cookies: cookiesFR,
    exercises: exercisesFR,
    formulary: formularyFR,
    privacy: privacyFR,
    refund: refundFR,
    termsOfService: termsOfServiceFR,
    termsOfSubscription: termsOfSubscriptionFR
  },
  it: {
    translation: translationIT,
    cookies: cookiesIT,
    exercises: exercisesIT,
    formulary: formularyIT,
    privacy: privacyIT,
    refund: refundIT,
    termsOfService: termsOfServiceIT,
    termsOfSubscription: termsOfSubscriptionIT
  }*/
};

i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'es', // lenguaje por defecto

    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },

    react: {
      useSuspense: true
    }
  }).then(() => {
  console.log('Traducciones cargadas');
});

export default i18n;
