import React, { lazy } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './i18n.js';
import { UserProvider } from './context/UserContext.js';
import {TokenProvider} from "./context/TokenContext";
import {BusinessProvider} from "./context/BusinessContext";

const App = lazy(() => import('./App.js'));

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <UserProvider>
      <TokenProvider>
          <BusinessProvider>
              <App />
          </BusinessProvider>
      </TokenProvider>
    </UserProvider>
  </React.StrictMode>
);

reportWebVitals();
